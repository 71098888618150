<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl">
            <span>캐시 출금 신청</span>
          </div>
          <div class="inform" style="margin-top: 0;">
            <div>
              출금 가능한 최소 금액은 2,000 캐시이며, 출금 수수료는 1,000캐시
              입니다.<br />
              계좌번호 변경은 회원 정보 수정에서, 입/출금 관련 문의사항은 1:1
              문의를 이용 해주세요.<br />
              1일 최대 출금 가능액은 5,000,000 캐시 미만입니다.
            </div>
          </div>
          <div class="withdraw_list">
            <div class="inner">
              <div class="withdraw_list_ttl">위즈 캐시 출금 정보</div>
              <div class="withdraw_list_con">
                <dl>
                  <dt>보유 위즈 캐시</dt>
                  <dd>
                    {{
                      $Util.formatNumber2(cash, {
                        decimalLength: 1,
                        unit: ' C'
                      })
                    }}
                  </dd>
                </dl>
                <dl>
                  <dt>출금 수수료</dt>
                  <dd>
                    {{ $Util.formatNumber2(fee, { unit: ' C' }) }}
                  </dd>
                </dl>
                <dl>
                  <dt>최대 출금시 예상액</dt>
                  <dd>
                    {{ $Util.formatNumber2(computedExpect, { unit: ' 원' }) }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="withdraw_list">
            <div class="inner">
              <div class="withdraw_list_ttl">입금 받을 계좌 정보</div>
              <div class="withdraw_list_con">
                <dl>
                  <dt>은행명</dt>
                  <dd>{{ bankNm }}</dd>
                </dl>
                <dl>
                  <dt>계좌번호</dt>
                  <dd>{{ bankAccountNumber }}</dd>
                </dl>
                <dl>
                  <dt>예금주</dt>
                  <dd>{{ bankAccountHolder }}</dd>
                </dl>
              </div>
              <div class="hint">
                * 회원님의 안전 출금을 위해, SMS 인증 및 비밀번호 확인을 추가로
                진행해주세요.
              </div>
            </div>
          </div>
          <div class="form_wrap" v-if="nextCnt === 0">
            <div class="withdraw_btn">
              <button class="btn bo" @click="fnPageMyInfo">
                계좌번호 변경
              </button>
              <button class="btn" @click="fnNext" v-if="type !== 3">
                {{ type === 1 ? '비밀번호 확인' : '휴대폰 인증' }}
              </button>
              <button class="btn" @click="fnPhoneAuth" v-else>
                휴대폰 인증
              </button>
            </div>
          </div>
          <div class="form_wrap" v-if="nextCnt === 1 && type === 1">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_in">
                    <div class="inline">
                      <label class="input">
                        <input
                          type="password"
                          placeholder="비밀번호 입력"
                          v-model="pw"
                        />
                      </label>
                      <button class="btn" @click="fnCheckPw">확인</button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="form_wrap" v-if="nextCnt === 1 && type === 2">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_in">
                    <button class="btn" @click="fnSendAuthValue">
                      인증번호 발송
                    </button>
                    <div class="inline">
                      <label class="input">
                        <input
                          type="number"
                          inputmode="decimal"
                          pattern="\d*"
                          placeholder="인증번호(6자리 숫자)"
                          :value="authValue"
                          @input="maskingAuthValue"
                          :disabled="$Util.isEmpty(remainTime)"
                        />
                      </label>
                      <button class="btn" @click="fnConfirm">
                        확인
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="blue_line center"
            style="margin: 1rem 0 0 0;"
            v-if="nextCnt === 2"
          >
            <div class="inner">
              <div>
                <span class="sec_color">비밀번호 확인이 완료되었습니다.</span>
              </div>
            </div>
          </div>
          <div
            class="blue_line"
            style="margin: 1rem 0 0 0;"
            v-if="nextCnt === 2"
          >
            <div class="form" style="padding-top: 1rem;">
              <div class="form_in">
                <div class="inner">
                  <div class="sec_color" style="font-weight: 500;">
                    출금할 캐시
                  </div>
                </div>
                <div class="inline">
                  <label class="input">
                    <input
                      type="number"
                      inputmode="decimal"
                      pattern="\d*"
                      :min="0"
                      @input="maskingAmountNumber"
                      :value="amount"
                    />
                  </label>
                  <!--                  <button class="btn bo">취소</button>&lt;!&ndash;'포인트 입력 시: bo 삭제'&ndash;&gt;-->
                </div>
              </div>
            </div>
            <div class="inner plain">
              <div>출금 수수료</div>
              <div>{{ $Util.formatNumber2(fee, { unit: '' }) }} C</div>
            </div>
            <div class="inner result">
              <div>총 입금액</div>
              <div>{{ $Util.formatNumber2(deposit, { unit: '' }) }} 원</div>
            </div>
          </div>
          <div class="con_btn" style="padding: 2rem;" v-if="nextCnt === 2">
            <div class="btn_wrap">
              <button class="btn" @click="fnRequest">출금 요청</button>
            </div>
          </div>
          <!--          <div class="form_wrap" v-if="nextCnt === 2">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_in">
                    <div class="inline">
                      <label class="input">
                        <input
                          type="number"
                          inputmode="decimal"
                          pattern="\d*"
                          placeholder="수수료가 포함된 금액 입력"
                          v-model="amount"
                          :min="0"
                          :disabled="isAll === 'Y'"
                        />
                      </label>
                      <label class="checkbox" style="flex: 0 0 auto;">
                        <input
                          type="checkbox"
                          v-model="isAll"
                          :true-value="'Y'"
                          :false-value="'N'"
                          :checked="'Y'"
                        />
                        <span class="label"><span>전액 출금</span></span>
                      </label>
                    </div>
                    <button class="btn" @click="fnRequest">출금 요청</button>
                  </div>
                </li>
              </ul>
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <nice
      :id="'formNice4'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'my-page-cash-point-withdraw-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
