import { computed, reactive, toRefs, getCurrentInstance, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { niceForm } from '@/common/form/NiceForm'
import { CommonFunction } from '@/common/CommonFunction.js'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    havePwYn: proxy.$ConstCode.BOOLEAN_VALUE.FALSE,
    type: computed(() => {
      if (!state.isRegisterBank) {
        return 3
      }
      if (state.havePwYn === proxy.$ConstCode.BOOLEAN_VALUE.TRUE) {
        // 비밀번호 체크
        return 1
      }
      // 휴대폰 인증
      return 2
    }),
    nextCnt: 0,
    isRegisterBank: true,
    bankAccountHolder: '',
    bankAccountNumber: '',
    bankNm: '',
    cash: 0,
    fee: 0,
    computedExpect: computed(() => {
      if (Number(state.cash) > 5001000) {
        return 5000000
      } else {
        return Math.floor(state.cash) - state.fee
      }
    }),
    pw: '',
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`,
    sendCnt: 0,
    authValue: '',
    secondTime: 0,
    endTime: '',
    timer: undefined,
    remainTime: '',
    amount: '',
    totalAmount: computed(() => {
      return Number(state.amount) + Number(state.fee)
    }),
    deposit: computed(() => {
      if (state.amount >= 2000) {
        return Number(state.amount) - state.fee
      } else {
        return 0
      }
    }),
    isAll: 'N'
  })
  const { fnCheckUserAuth, fnMyInfo, fnUpdateAuthType } = CommonFunction(state)
  const fnInfo = async () => {
    const res = await proxy.$MyPageSvc.postMyWizzCashWithdrawAccountInfo()
    if (res.resultCode === '0000') {
      state.bankAccountHolder = res.bankAccountHolder
      state.bankAccountNumber = res.bankAccountNumber
      state.bankNm = res.bankNm
      state.cash = res.cash
      state.fee = res.fee
    }
  }
  const fnNext = () => {
    if (
      proxy.$Util.isEmpty(state.bankAccountHolder) ||
      proxy.$Util.isEmpty(state.bankAccountNumber) ||
      proxy.$Util.isEmpty(state.bankNm)
    ) {
      alert('계좌 정보가 없습니다.')
      return
    }
    state.nextCnt++
  }
  const fnCheckPw = async () => {
    if (proxy.$Util.isEmpty(state.pw)) return
    const params = {}
    params.pw = state.pw
    const res = await proxy.$MyPageSvc.postMyUserInfoCheckPassword(params)
    if (res.resultCode === '0000') {
      state.nextCnt++
    } else if (res.resultCode === '0001') {
      alert('비밀번호를 정확히 입력해 주세요. ')
    } else {
      alert(res.resultMsg)
    }
  }
  const fnSendAuthValue = async () => {
    const res = await proxy.$MyPageSvc.postMyWizzCashWithdrawAuthSendSms()
    if (res.resultCode === '0000') {
      state.sendCnt++
    } else {
      alert(res.resultMsg)
    }
  }
  const fnConfirm = async () => {
    if (!isValid()) return
    const params = {}
    params.authValue = state.authValue
    const res = await proxy.$MyPageSvc.postMyWizzCashWithdrawAuthCompare(params)
    if (res.resultCode === '0000') {
      alert('인증되었습니다.')
      clearInterval(state.timer)
      state.timer = undefined
      state.remainTime = ''
      fnNext()
    } else if (res.resultCode === '9001') {
      alert('인증번호를 확인해주세요.')
    } else {
      alert(res.resultMsg)
    }
  }
  const fnPageMyInfo = async () => {
    if (!state.isRegisterBank) {
      alert('계좌 정보가 없습니다.')
      return false
    }
    await fnMyInfo()
  }
  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }
  const isValid = () => {
    if (proxy.$Util.isEmpty(state.authValue)) {
      alert('인증번호를 정확히 입력해주세요.')
      return false
    }
    if (state.authValue.length !== 6) {
      alert('인증번호를 정확히 입력해주세요.')
      return false
    }
    return true
  }
  const isValidRequest = () => {
    if (proxy.$Util.isEmpty(state.amount)) {
      alert('출금할 캐시를 확인해주세요.')
      return false
    }
    if (Number(state.deposit) < 1000 || state.deposit > 5000000) {
      alert('2,000캐시 이상부터 출금, 일 최대 5,000,000캐시출금이 가능합니다.')
      return false
    }
    if (Number(state.amount) > Math.floor(state.cash)) {
      alert('보유하신 캐시를 초과할 수 없습니다.')
      return false
    }
    // if (Number(state.amount) > state.cash) {
    //   alert('보유하신 금액을 초과할 수 없습니다.')
    //   return false
    // }
    return true
  }
  const fnFormatRemainTime = () => {
    const now = proxy.$dayjs()
    const minute = state.endTime.diff(now, 'minute')
    const second = state.endTime.diff(now, 'second')
    state.remainTime = `${proxy.$Util.lpad(
      `${minute % 60}`,
      2,
      '0'
    )}:${proxy.$Util.lpad(`${second % 60}`, 2, '0')}`
  }
  const fnRequest = async () => {
    if (!isValidRequest()) return
    if (
      !confirm(
        `출금신청액 ${proxy.$Util.formatNumber2(state.amount, {
          unit: ''
        })}캐시, 수수료 ${proxy.$Util.formatNumber2(state.fee, {
          unit: ''
        })}캐시,\n총 입금액 ${proxy.$Util.formatNumber2(state.deposit, {
          unit: ''
        })}원 입금됩니다.`
      )
    )
      return
    const params = {}
    params.amount = state.deposit
    const res = await proxy.$MyPageSvc.postMyWizzCashInsertWithdraw(params)
    if (res.resultCode === '0000') {
      alert(
        '출금요청이 접수되었습니다.\n회원님의 계좌로 입금까지 영업일 기준 3일 이내에 처리됩니다.'
      )
      alert(
        '현금영수증 발행 신청이 정상 처리되었습니다.\n입금일 기준 발행되며, 현금영수증의 상새 내용 확인은 1:1 문의를 통해 문의하시거나 홈텍스 (https://www.homtex.co.kr)에서 발행내용을 확인하실 수 있습니다.'
      )
      await router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }
  const fnPhoneAuth = async () => {
    await fnMyInfo(1)
  }

  const maskingAmountNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (e.target.value > 5000999) {
      if (5000999 < state.cash) {
        e.target.value = 5000999
      } else {
        e.target.value = Math.floor(state.cash)
      }
    } else if (e.target.value > state.cash) {
      e.target.value = Math.floor(state.cash)
    }
    state.amount = e.target.value
  }

  const maskingAuthValue = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 6) {
      e.target.value = e.target.value.substr(0, 6)
    }
    state.authValue = String(e.target.value)
  }

  /** watch **/
  watch(
    () => state.sendCnt,
    () => {
      if (!proxy.$Util.isEmpty(state.timer)) {
        clearInterval(state.timer)
        state.timer = undefined
        state.remainTime = ''
      }
      state.endTime = proxy
        .$dayjs()
        .add(
          proxy.$ConstCode.CODE_ENV_LIST.PHONE_AUTH_SECOND * 1000,
          'millisecond'
        )
      state.secondTime = proxy.$ConstCode.CODE_ENV_LIST.PHONE_AUTH_SECOND
      fnFormatRemainTime()
      state.timer = setInterval(() => {
        --state.secondTime
      }, 1000)
    }
  )
  watch(
    () => state.secondTime,
    val => {
      if (val < 1) {
        clearInterval(state.timer)
        state.timer = undefined
        state.remainTime = ''
        state.authValue = ''
        alert('인증시간이 만료되었습니다. 인증번호를 재발송 해주세요.')
      } else {
        fnFormatRemainTime()
      }
    }
  )
  watch(
    () => state.isAll,
    val => {
      if (val === 'Y') {
        state.amount = state.cash
      } else {
        state.amount = ''
      }
    }
  )
  const init = async () => {
    state.havePwYn = await fnCheckUserAuth()
    await fnInfo()
    if (
      proxy.$Util.isEmpty(state.bankAccountHolder) ||
      proxy.$Util.isEmpty(state.bankAccountNumber) ||
      proxy.$Util.isEmpty(state.bankNm)
    ) {
      state.isRegisterBank = false
    }
  }
  init()
  return {
    ...toRefs(state),
    fnPageMyInfo,
    fnNext,
    fnCheckPw,
    fnSendAuthValue,
    fnConfirm,
    fnCallback,
    fnRequest,
    fnPhoneAuth,
    maskingAmountNumber,
    maskingAuthValue
  }
}
